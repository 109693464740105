import ApiService from '@/assets/js/services/api.service';

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    loadGoods({ commit, dispatch }, params) {
      return ApiService.get('api/client/goods', params);
    },
    loadGoodsById({ commit, dispatch }, params) {
        return ApiService.get('api/client/goods/' + params.id);
    },
    loadGoodsByChatId({ commit, dispatch }, params) {
        return ApiService.get('api/client/goods/chat/' + params.id);
    },
    saveGoods({commit, dispatch}, body) {
      return ApiService.post('api/client/goods', body);
    },
    editGoods({commit, dispatch}, body) {
      return ApiService.put('api/client/goods/' + body.id, body);
    },
    deleteGoods({ commit, dispatch }, params) {
      return ApiService.delete('api/client/goods/' + params.id);
    },
    deliveryRequest({commit, dispatch}, payload) {
      return ApiService.post('api/client/deliveries/' + payload.goodsId, payload.dto);
    }
  },
  getters: {
  }
};
