<template>
  <div class="modal-wrapper" id="modal-choose-cdek-delivery-point">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')"></div>

        <div class="h1">{{ $t('delivery.selectCdekAddress') }}</div>

        <div class="search">
          <GmapAutocomplete @place_changed="setPlace">
            <template v-slot:input="slotProps">
              <input
                  ref="input"
                  type="text"
                  name="location"
                  :placeholder="$t('modal.location.search-location')"
                  v-on:listeners="slotProps.listeners"
                  v-on:attrs="slotProps.attrs"
                  :select-first-on-enter="true"
              />
            </template>
          </GmapAutocomplete>
        </div>

        <div id="map-modal" v-if="!!(google)">
          <google-map-loader
              id="delivery-map"
              apiKey="AIzaSyD94VydEISej3Y6hk7T0JmpxlmKITiEIDc"
              ref="deliveriesMapRef"
              :an-object-api-address="'api/client/deliveries/objects'"
              style="height:100%;"
          />
        </div>

        <div class="btn-line" style="display: flex; flex-direction: row-reverse; gap: 16px">
          <button class="btn" :disabled="!pointData" @click="onApplyMarkerClick">{{ $t('common.save') }}</button>
          <div class="delivery-info" v-if="pointData">
            <img src="/images/cdek-logo.webp" alt="cdek-icon" class="cdek-icon">
            <div class="info-text">
              <span class="label">{{ $t('delivery.selectedDeliveryPoint') }}</span>
              <span class="address">{{ pointData.item.description ? pointData.item.description : "CDEK" }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import modal from '../../assets/js/init/modal';
import { eventBus } from '@/main.js';
import { mapActions } from 'vuex';
import GoogleMapLoader from "@/components/GoogleMapLoader";
import {gmapApi} from "gmap-vue";

export default {
  name: 'ChooseCdekDeliveryPointModal',
  components: { GoogleMapLoader },
  computed: {
    google: gmapApi,
  },
  data() {
    return {
      pointData: null,
    };
  },

  mounted() {
    eventBus.$on('google-map-loader-item-click', (data) => {
      this.pointData = data;
    });
  },

  methods: {
    setPlace(place) {
      if(!place.geometry)
        return;

      this.$refs.deliveriesMapRef.setMapPosition(place.geometry.location);
    },

    onApplyMarkerClick() {
      eventBus.$emit('cdek-delivery-point-chosen', {...this.pointData});
      this.pointData = null;
      modal.hideModal();
    },
  }
};
</script>
<style>

.delivery-info {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.cdek-icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.info-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}


.label {
  font-weight: bold;
  margin-bottom: 4px;

}

.address {
  flex-grow: 1; flex-shrink: 1; flex-basis: 0;
}

</style>