<template>
  <div>
    <div id="map" style="width:100%; height:100%;"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
          :google="google"
          :map="map"
      />
    </template>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import ApiService from "@/assets/js/services/api.service";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {eventBus} from "@/main";

/**
 * Это отдельный компонент карты, который используется на главном экране, а также в модале CdekDelivery.
 * Он работает с кастомизированными маркерами, умеет отображать кластеры. Для более простого отображения также
 * используется плагин на Vue, но он больше подходит для простых точек.
 */
export default {
  name: "GoogleMapLoader",
  props: {
    apiKey: String,
    anObjectApiAddress: String, // что конкретно загружать. Пустота в этом поле означает: api/client/objects
  },

  computed: {
    ...mapGetters({
      objects: 'objects',
      userGeo: 'userGeo',
      gmapState: 'gmapState',
      params: 'objectsParams'
    }),
  },

  data() {
    return {
      google: null,
      map: null,
      markers: [],
      customMarker: null,
      loading: false,
    }
  },
  watch: {
    gmapState: {
      handler: function (newValue, oldValue) {
        this.map.panTo(newValue)
      },
      deep: true,
    }
  },

  async mounted() {
    this.google = new Loader({
      apiKey: this.apiKey,
      version: "weekly",
      libraries: ["places"],
    });

    this.initializeMap();
  },

  methods: {
    ...mapActions(['getObjectsList']),
    ...mapMutations(['setBounds', 'saveLastMapPosition', 'setLocation']),
    updateMap: async function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const center = this.map.getCenter();
      const bounds = this.map.getBounds();
      let southWest = bounds.getSouthWest();
      let northEast = bounds.getNorthEast();

      this.saveLastMapPosition({
        lat: center.lat(),
        lng: center.lng(),
        zoom: this.map.getZoom(),
      });

      let p = {...this.params};
      p.latitude = this.userGeo.latitude;
      p.longitude = this.userGeo.longitude;
      p.latitudeBl = southWest.lat();
      p.longitudeBl = southWest.lng();
      p.latitudeTr = northEast.lat();
      p.longitudeTr = northEast.lng();
      p.limit = 50;
      p.style = "map";


      let address = this.anObjectApiAddress;
      if (!address) {
        address = 'api/client/objects';
      }

      let result = await ApiService.get(address, p); // загружаем объекты не через store, чтобы товары в режиме списка не изменились

      function removeElementsByClass(className){
        const elements = document.getElementsByClassName(className);
        while(elements.length > 0){
          elements[0].parentNode.removeChild(elements[0]);
        }
      }

      removeElementsByClass("gmap-marker");

      for (let i = 0; i < this.markers.length; i++) {
        let marker = this.markers[i];
        marker.setMap(null);
        this.markers = [];
      }

      let allMarkers = {
        markers: [],
        clusters: [],
        donates: [],
        deliveryPoints: [],
        multipleGoods: [],
      };

      if (result && result.data) {
        for (let i = 0; i < result.data.length; i++) {
          const good = result.data[i];

          if (good.objects.length === 1 && good.objects[0].type == 'donate') {
            allMarkers.donates.push({
              lat: good.geo.latitude,
              lng: good.geo.longitude,
              id: good.objects[0].id,
              favoriteResourceId: good.objects[0].favoriteResourceId,
            });
            continue;
          }

          // Deliveries
          if (good.objects.length === 1 && good.objects[0].type == 'delivery') {
            allMarkers.deliveryPoints.push({
              lat: good.geo.latitude,
              lng: good.geo.longitude,
              id: good.objects[0].id,
              favoriteResourceId: good.objects[0].favoriteResourceId,
              description: good.geo.description,
            });
            continue;
          }

          if (good.objects.length > 1) {
            allMarkers.multipleGoods.push({
              lat: good.geo.latitude,
              lng: good.geo.longitude,
              objects: good.objects,
            });
            continue;
          }

          if (good.cluster) {
            allMarkers.clusters.push({
              lat: good.geo.latitude,
              lng: good.geo.longitude,
              title: good.count + "",
            });
          } else {
            allMarkers.markers.push({
              lat: good.geo.latitude,
              lng: good.geo.longitude,
              marker: good,
            });
          }
        }
      }

      let models = [];

      //
      //
      // DONATES
      for (let i = 0; i < allMarkers.donates.length; i++) {
        let m = allMarkers.donates[i];
        let resourceUrl = this.createResourceUrl(m.favoriteResourceId);
        let r = {
          latlng: new google.maps.LatLng(m.lat, m.lng),
          class_name: 'map-img',
          link: m.id,
          html: '<span class="img-holder"><img src="' + resourceUrl + '"/></span>',
        };
        models.push(r);
      }


      //
      //
      // DELIVERY POINTS
      for (let i = 0; i < allMarkers.deliveryPoints.length; i++) {
        let m = allMarkers.deliveryPoints[i];
        let resourceUrl = this.createResourceUrl(m.favoriteResourceId);
        let r = {
          latlng: new google.maps.LatLng(m.lat, m.lng),
          class_name: 'map-marker-image',
          type: "delivery",
          description: m.description,
          link: m.id,
          html: '<span class="img-holder"><img src="' + resourceUrl + '"/></span>',
          //html: '<span class="img-holder"><img src="/images/cdek-logo.webp"/></span>',
        };
        models.push(r);
      }

      //
      //
      // CLUSTERS
      for (let i = 0; i < allMarkers.clusters.length; i++) {
        let m = allMarkers.clusters[i];
        let r = {
          latlng: new google.maps.LatLng(m.lat, m.lng),
          class_name: 'map-marker',
          isCluster: true,
          html: '<div class="num" style="cursor: pointer">' + m.title + '</div>'
        };
        models.push(r);
      }

      //
      //
      // GOOD
      for (let i = 0; i < allMarkers.markers.length; i++) {
        let m = allMarkers.markers[i];
        let obj = m.marker.objects[0];
        let favoriteResourceId = obj.favoriteResourceId;
        let resourceUrl = this.createResourceUrl(favoriteResourceId);

        let r = {
          latlng: new google.maps.LatLng(m.lat, m.lng),
          class_name: "map-marker-image",
          html: '<span class="img-holder"><img src="' + resourceUrl + '"/></span>',
          link: obj.id,
          type: obj.type,
          info_count: 1,
        };
        models.push(r);
      }

      //
      //
      // MULTIPLE GOODS (OR DELIVERY POINTS!)
      for (let i = 0; i < allMarkers.multipleGoods.length; i++) {
        let m = allMarkers.multipleGoods[i];
        let firstObject = m.objects[0];

        let infoHtml = '<ul class="info-list">';
        for (let j = 0; j < m.objects.length; j++) {
          let good = m.objects[j];
          let resourceUrl = this.createResourceUrl(good.favoriteResourceId);
          infoHtml += `<li><a id="${good.id}" href="javascript:;"><img src="${resourceUrl}" alt="#"/></a></li>`;
          //infoHtml += `<li><a id="${good.id}" href="javascript:;"><img src="/images/cdek-logo.webp" alt="#"/></a></li>`; // for testing
        }
        infoHtml += '</ul>';

        let r = {
          latlng: new google.maps.LatLng(m.lat, m.lng),
          class_name: 'map-marker',
          html: '<div class="dotts"></div>',
          info_count: m.objects.length,
          info_html: infoHtml,
          type: firstObject.type,
          description: firstObject.description,
        };
        models.push(r);
      }

      for (let i = 0; i < models.length; i++) {
        let element = models[i];
        var marker = new this.customMarker(element.latlng, this.map, {
          class_name: element.class_name,
          title: element.title,
          link: element.link,
          html: element.html,
          type: element.type,
          isCluster: element.isCluster,
          info_count: element.info_count,
          info_html: element.info_html,
          latlng: element.latlng,
          description: element.description,
        });

        this.markers.push(marker);
      }

      this.loading = false;
    },

    setMapPosition(geo) {
      this.map.panTo(geo);
    },

    createResourceUrl(id) {
      if (id) {
        return appSettings.baseUrl + 'api/client/resource/' + id;
      } else {
        return null;
      }
    },

    /**
     * Perform action on marker click
     * @param actionType What to do on click
     * @param id Point id
     * @param item Item itself
     */
    onItemClick: function(actionType, id, item) {
      switch (actionType) {
        case "bus": // bus means emit event
          if (id != null) { // emit only when clicked on element with id
            eventBus.$emit('google-map-loader-item-click', {pointId: id, item: item});
          }
          break;
        default: // otherwise go to the corresponding page
          this.$router.push({name: actionType, params: { id: id }});
          break;
      }
    },

    initializeMap() {
      let panTo = this.gmapState;
      let zoom = this.gmapState.zoom;

      this.google.loadCallback(e => {
        if (e) {
          console.log(e);
          return;
        }

        function customMarker(latlng, map, args) {
          this.latlng = latlng;
          this.args = args;
          this.setMap(map);
        }

        customMarker.prototype = new google.maps.OverlayView();

        let that = this;
        let coef = 0;
        if ($(window).width() > 1000) {
          coef = $(window).width() / 1440;
        } else if($(window).width() > 540) {
          coef = $(window).width() / 768;
        } else {
          coef = $(window).width() / 320;
        }

        customMarker.prototype.draw = function() {
          var self = this,
              div = this.div;

          if (!div) {
            if (typeof(self.args.link) !== 'undefined') {
              div = this.div = document.createElement('a');
            } else {
              div = this.div = document.createElement('div');
            }

            let actionType = null;
            if (typeof(self.args.class_name) !== 'undefined') {
              div.className = self.args.class_name;

              if (div.className == "map-marker-image" && !self.args.isCluster && self.args.info_count === 1) { actionType = self.args.type };
              if (div.className == "map-img") { actionType = "donate"; }
            }

            if (self.args.isCluster) { actionType = "cluster"; }
            if (self.args.type == "delivery") { actionType = "bus"; }

            div.classList.add("gmap-marker");

            if (actionType != null) {
              div.addEventListener("click", function () {
                if (actionType != "cluster") {
                  that.onItemClick(actionType, self.args.link, self.args);
                } else {
                  const zoom = that.map.getZoom();
                  that.map.panTo(self.latlng);
                  that.map.setZoom(zoom * 1.2);
                }
              });
            }

            if (typeof(self.args.id) !== 'undefined') {
              div.id = self.args.id;
            }
            if (typeof(self.args.title) !== 'undefined') {
              div.title = self.args.title;
            }
            if (typeof(self.args.html) !== 'undefined') {
              div.innerHTML = self.args.html;
            }
            if (typeof(self.args.info_html) !== 'undefined') {
              div.innerHTML += '<div class="info c' + self.args.info_count + '" data-pos="0" data-total="' + self.args.info_count + '">' + self.args.info_html + (self.args.info_count > 3 ? '<button class="btn-prev" disabled="disabled"></button><button class="btn-next">' + this.$t('common.next') +'</button>' : '') + '</div>';
              div.classList.add('has-info');

              div.querySelectorAll("a").forEach(function(e) {
                e.addEventListener("click", function(e) {
                  that.onItemClick(actionType ? actionType : "goods", this.id, self.args);
                });
              });

              div.addEventListener('click', function(e) {
                e.preventDefault();
                if (e.target.closest('.info') == null) {
                  if (!this.classList.contains('act')) {
                    this.querySelector('.info').style.display = 'block';
                    this.classList.add('act');
                  } else {
                    this.querySelector('.info').style.display = 'none';
                    this.classList.remove('act');
                  }
                }
              });

              if (self.args.info_count > 3) {
                var delta = 76 * coef;
                div.querySelector('.info-list').scrollLeft = 0;

                div.querySelector('.btn-prev').addEventListener('click', function(e) {
                  e.stopPropagation();
                  var info = this.parentNode;
                  var list = info.querySelector('.info-list');
                  var btn_prev = this;
                  var btn_next = info.querySelector('.btn-next');
                  var pos = info.getAttribute('data-pos');
                  var total = info.getAttribute('data-total');

                  if (pos > 0) {
                    pos--;
                    list.scroll({
                      left: pos * delta,
                      top: 0,
                      behavior: 'smooth'
                    })
                    info.setAttribute('data-pos', pos);

                    btn_next.disabled = false;
                    if (pos <= 0) btn_prev.disabled = true;
                  }
                });
                div.querySelector('.btn-next').addEventListener('click', function(e) {
                  e.stopPropagation();
                  var info = this.parentNode;
                  var list = info.querySelector('.info-list');
                  var btn_prev = info.querySelector('.btn-prev');
                  var btn_next = this;
                  var pos = info.getAttribute('data-pos');
                  var total = info.getAttribute('data-total');

                  if (pos - 0 + 3 < total) {
                    pos++;
                    list.scroll({
                      left: pos * delta,
                      top: 0,
                      behavior: 'smooth'
                    })
                    info.setAttribute('data-pos', pos);

                    btn_prev.disabled = false;
                    if (pos - 0 + 3 >= total) btn_next.disabled = true;
                  }
                });
              }
            }

            google.maps.event.addDomListener(div, 'click', function (event) {
              google.maps.event.trigger(self, 'click');
            });

            var panes = self.getPanes();
            panes.overlayImage.appendChild(div);
          }

          var point = self.getProjection().fromLatLngToDivPixel(self.latlng);

          if (point) {
            div.style.left = point.x + 'px';
            div.style.top = point.y + 'px';
          }
        };
        customMarker.prototype.onAdd = function() {};
        customMarker.prototype.onRemove = function() {};

        this.customMarker = customMarker;

        let center = {lat: 55, lng: 34};
        if (panTo != null) {
          center = { lat: panTo.lat, lng: panTo.lng };
        }

        let mapConfig = {
          zoom: zoom ? zoom : 3,
          center: center,
          navigationControlOptions: {
            style: google.maps.NavigationControlStyle.SMALL
          },
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          disableDefaultUI: true,
          zoomControl: true
        };

        let gmap = new google.maps.Map(document.getElementById("map"), mapConfig);
        this.map = gmap;

        google.maps.event.addListener(gmap, 'idle', this.updateMap);
      });
    }
  }
}
</script>