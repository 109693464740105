<template>
  <div class="holder wrap">
    <a href="" id="logo" @click.prevent="goToMain">
      <img v-if="user == null || user.theme == null || user.theme.webLogoResourceId == null" src="../../public/images/logo.svg" :alt="$t('header.for-free')" :title="$t('header.for-free')">
      <img v-else :src="createResourceUrl(user.theme.webLogoResourceId)" :alt="$t('header.for-free')" :title="$t('header.for-free')">
    </a>
    <header-buttons/>
    <a href="" @click.prevent="createNewItem" id="add-item" class="type2"><span>{{ $t('header.add-item') }}</span></a>
    <a id="profile"><img :src="createResourceUrl(user.resourceId)" :alt="$t('header.user')" :title="$t('header.user')"></a>
    <a id="karmitts" @click="onBalanceClicked">{{ user.balance }}</a>
  </div>
</template>

<script>
import LoginModal from '../components/modals/LoginModal.vue';
import HeaderButtons from '../components/layouts/HeaderButtons.vue';
import {eventBus} from '../main.js';
import { mapGetters } from 'vuex';
import settings from '../assets/js/settings';

export default {
  name: 'AuthHeader',
  components: { HeaderButtons, LoginModal },
  computed: {
    ...mapGetters(['user'])
  },
  created() {
    console.log('CREATED:');
    console.log(this.user);
  },
  mounted() {
    console.log('MOUNTED:');
    console.log(this.user);
  },
  methods: {
    createNewItem() {
      if(settings.__isMobile) {
        $('#mn-holder .close').click();
      }
      this.$router.push({ name: 'addItem'});
    },
    goToMain() {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'objects' });
    },
    createResourceUrl(id) {
      if (id) {
        return appSettings.baseUrl + 'api/client/resource/' + id;
      } else {
        return require('../../public/images/icons/ico_account.jpg');
      }
    },
    onBalanceClicked() {
      this.showToast(this.$t('toast.1-by-1'), 'info')
    }
  }
};
</script>

<style scoped>

</style>
