<template>
  <div id="container" ref="modalContainer" @click="closeModal">
    <div id="modal-fadeout"></div>

    <login-modal/>
    <confirm-registration-modal/>
    <change-password-modal/>

    <calendar-modal/>

    <approve-modal/>
    <booking-modal/>
    <upload-photo-modal/>
    <location-modal/>
    <choose-cdek-delivery-point-modal/>
    <end-chat-modal/>
    <complain-modal/>

    <discount-modal/>
    <price-modal/>
    <sale-modal/>

    <donate-modal/>
    <photo-modal/>
    <participation-modal/>

    <are-you-sure-modal/>

    <profile-change-name-modal/>
    <profile-change-email-modal/>
    <profile-change-password-modal/>
    <profile-edit-confirmation-modal/>
    <profile-change-avatar/>
    <delete-user-confirmation-modal/>
    <profile-change-company-modal/>
  </div>
</template>

<script>
  import LoginModal from '../components/modals/LoginModal.vue';
  import UploadPhotoModal from './modals/UploadPhotoModal';
  import modal from '../assets/js/init/modal';
  import CalendarModal from './modals/CalendarModal';
  import DiscountModal from './modals/DiscountModal';
  import PriceModal from './modals/PriceModal';
  import ConfirmRegistrationModal from './modals/ConfirmationModal';
  import ChangePasswordModal from './modals/ChangePasswordModal';
  import LocationModal from './modals/LocationModal';
  import ApproveModal from './modals/ApproveModal';
  import BookingModal from './modals/BookingModal';
  import EndChatModal from './modals/EndChatModal';
  import ComplainModal from './modals/ComplainModal';
  import DonateModal from './modals/DonateModal';
  import PhotoModal from './modals/PhotoModal';
  import ParticipationModal from './modals/ParticipationModal';
  import SaleModal from './modals/SaleModal';
  import AreYouSureModal from './modals/AreYouSureModal';
  import ProfileChangePasswordModal from './modals/edit/ProfileChangePasswordModal';
  import ProfileEditConfirmationModal from './modals/edit/ProfileEditConfirmationModal';
  import ProfileChangeEmailModal from './modals/edit/ProfileChangeEmailModal';
  import ProfileChangeNameModal from './modals/edit/ProfileChangeNameModal';
  import ProfileChangeAvatar from './modals/edit/ProfileChangeAvatar';
  import DeleteUserConfirmationModal from "./modals/DeleteUserConfirmationModal";
  import ProfileChangeCompanyModal from './modals/edit/ProfileChangeCompanyModal';
  import ChooseCdekDeliveryPointModal from "@/components/modals/ChooseCdekDeliveryPointModal";

  export default {
    name: 'ModalContainer',
    components: { ProfileChangeAvatar, ProfileChangeNameModal, ProfileChangeEmailModal, ProfileEditConfirmationModal,
      ProfileChangePasswordModal, SaleModal, DonateModal, PhotoModal, ParticipationModal, ComplainModal, EndChatModal,
      ApproveModal, BookingModal, LocationModal, ChangePasswordModal, ConfirmRegistrationModal, PriceModal, DiscountModal,
      CalendarModal, UploadPhotoModal, LoginModal, AreYouSureModal, DeleteUserConfirmationModal, ProfileChangeCompanyModal,
      ChooseCdekDeliveryPointModal},

    mounted() {
      modal.init();
      modal.closeModalInit()
    },
    methods: {
      closeModal() {
       /* modal.hideModal();
        profileSideBar.hide();*/
      }
    }
  };
</script>

<style scoped>
  #container {
    overflow: auto;
  }
</style>
